import React from "react";
import "./Landing.css";
import Navbar from "../../components/UI/Navbar/navbar";
import Section1 from "./section/section1";
import Section2 from "./section/section2";
import Section3 from "./section/section3";
import Section4 from "./section/section4";
import Section5 from "./section/section5";
import Section6 from "./section/section6";
import Section7 from "./section/section7";
import Section8 from "./section/section8";
import Contactus from "./section/contactus";
import Newsection from "./section/newSection";
import Footer from "../../components/UI/Footer";
import Newsection2 from "./section/newSection2/index";
import Sec2 from "./section/Secondsection/index";
import GlobalContextProvider from "../../Globalcontext";
import {
  Guidelinesmodal,
  PrivacyPolicyCookiesmodal,
  Safetysecuritymodal,
  Termsofusemodel,
} from "../../components/UI/Modals";

const Landingpage = () => {
  return (
    <GlobalContextProvider>
      <div>
        <Navbar />
        {/* <Section1 /> */}
        {/* <Section2 /> */}
        <Section3 />
        <Sec2 />
        <Newsection2 />
        <Section8 />
        {/* <Section4 /> */}
        {/* <Section5 /> */}
        {/* <Section6 /> */}
        {/* <Section7 /> */}
        <Newsection />

        <Contactus />
        <Footer />
        <Termsofusemodel />
        <Safetysecuritymodal />
        <Guidelinesmodal />
        <PrivacyPolicyCookiesmodal />
      </div>
    </GlobalContextProvider>
  );
};

export default Landingpage;
