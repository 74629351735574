import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import d1Img from "../../../../assets/download/02.png";
import d2Img from "../../../../assets/download/03.png";
import d3Img from "../../../../assets/download/04.png";
import d4Img from "../../../../assets/download/05.png";
import QRcode from "../../../../assets/download/Qrcode.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Download">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>“TurkAfrica B2B Uygulaması”nı İndirin</h1>
          <p>Afrika ve Türkiye'nin en parlak iş insanlarının buluştuğu yer.</p>
        </div>
        {/* <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div> */}
        <div className="download_img_main_div">
          <div className="div_of_image_box">
            <p>
              Profesyonel bir iş profili oluşturun ve potansiyel iş ortaklarını
              cezbedin.
            </p>
            <div className="img_of_download">
              <img src={d1Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Afrika ve Türkiye arasındaki ticari bağlar hakkında en son
              bilgileri alın! Bilgilenin.
            </p>
            <div className="img_of_download">
              <img src={d2Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Sesli, görüntülü veya sadece sohbet ederek ağ kurun ve iletişim
              kurun!
            </p>
            <div className="img_of_download">
              <img src={d3Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>İş ortaklarınızla ve ortaklarınızla video konferans yapın.</p>
            <div className="img_of_download">
              <img src={d4Img} alt="" />
            </div>
          </div>
        </div>
        <div className="store_div_download_section">
          <p>
            "Uygulamayı edinin! Şimdi indirmek ve anında deneyimlemek için QR
            Kodunu tarayın!”
          </p>
          <div className="Qr_code_div">
            <img src={QRcode} alt="" />
          </div>
          <p>veya</p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className="tooltip-container">
              <span className="tooltip">Şimdi indir</span>

              <a
                className="text store_img"
                href="https://apps.apple.com/app/turkafrica-b2b-app/id6475347626"
                target="_blank"
                rel="noopener noreferrer">
                <img src={applelogo} alt="Apple Store" />
              </a>
            </div>
            <div className="tooltip-container">
              <span className="tooltip">Şimdi indir</span>

              <a
                className="text store_img"
                href="https://play.google.com/store/apps/details?id=com.amin.turk_africa"
                target="_blank"
                rel="noopener noreferrer">
                <img src={playlogo} alt="Google Play Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
