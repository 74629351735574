import React from "react";
import "./secondsectin.css";
import sec6img from "../../../../assets/Buissnesspartner.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div_bus">
        <div className="Newsection2_img_div_bus">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection2_text_div">
          <p>
            L'application <span>TurkAfrica B2B</span> est la plus grande
            plateforme de réseautage B2B, connectant les entreprises et les
            entrepreneurs de la Turquie et de l'Afrique. Avec une vaste base de
            données d'investisseurs, d'associations commerciales, d'entreprises
            et d'organisations commerciales, l'application crée un espace
            collaboratif qui favorise le commerce bilatéral, l'investissement et
            l'innovation. En tirant parti de la technologie avancée,
            l'application TürkAfrica B2B permet aux utilisateurs de découvrir de
            nouvelles opportunités, de bâtir des partenariats stratégiques et de
            libérer tout le potentiel de ces marchés dynamiques.
          </p>
        </div>
        {/* Download */}
      </div>
    </div>
  );
};

export default index;
