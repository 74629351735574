import React from "react";
import "./Newsection2.css";
import sec6img from "../../../../assets/why_wait.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const Index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div">
        <div className="Newsection2_text_div">
          <h1>Neden Bekleyelim?</h1>
          <p>
            <span>Yeni Pazarları Keşfedin:</span> Afrika ve Türkiye'deki geniş
            bir iş ağına ve tüketici kitlesine erişin.
          </p>
          <p>
            <span>Stratejik Ortaklıklar Kurun:</span>
            Potansiyel ortaklar, yatırımcılar ve iş dernekleri ile doğrudan
            bağlantı kurun.
          </p>
          <p>
            <span>Ücretsiz Sohbet Edin:</span> Vizyonunuzu paylaşan işletmelerle
            anında ve ücretsiz olarak sohbet edin.
          </p>
          <p>
            TurkAfrica B2B Uygulaması'nı <span>ÜCRETSİZ</span> indirin ve
            işletmenizi küresel olarak genişletmek için ilk adımı atın.
            İşletmenizin geleceği sadece bir dokunuş uzağınızda!
          </p>
          <Link className="PRE_btn" to="Download" offset={-100}>
            Fırsatı Kaçırmayın—Bize Katılın!
          </Link>
        </div>
        <div className="Newsection2_img_div">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
