import React from "react";
import "./Newsection.css";
import sec6img from "../../../../assets/Newsection.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";

const Index = () => {
  return (
    <div className="Newsection" id="Investors">
      <div className="submain_Newsection_div">
        <div className="Newsection_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection_text_div">
          <h1>
            Shareholding and Investment <br /> Opportunities
          </h1>
          <p>
            Should you have an interest in exploring opportunities to become a
            valued shareholder or wish to invest in <b>TurkAfrica B2B App</b>,
            we warmly invite you to reach out to us. For further details and to
            initiate a conversation, please kindly make your way to the
            'Contact' section. We eagerly anticipate the opportunity to connect
            with you.
          </p>
          <div className="Newsection_store_div">
            <p>App Dwonload now</p>
            <div
              className="store_img"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <div className="tooltip-container">
                <span className="tooltip">Dwonload now</span>

                <a
                  className="text store_img"
                  href="https://apps.apple.com/app/turkafrica-b2b-app/id6475347626"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={applelogo} alt="Apple Store" />
                </a>
              </div>
              <div className="tooltip-container">
                <span className="tooltip">Dwonload now</span>

                <a
                  className="text store_img"
                  href="https://play.google.com/store/apps/details?id=com.amin.turk_africa"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={playlogo} alt="Google Play Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
