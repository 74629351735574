import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage/index.jsx";
import Thankyou from "./pages/thankyou/index.jsx";
import LandingpageTA from "./pages/LandingPageTA/index.jsx";
import LandingpageFR from "./pages/LandingPageFR/index.jsx";
import LandingpagePT from "./pages/LandingPagePT/index.jsx";
import LandingpageSP from "./pages/LandingPageSP/index.jsx";
import LandingpageSW from "./pages/LandingPageSW/index.jsx";
import Trems from "./pages/Trems/index.jsx";
import Privacy from "./pages/PrivacyPolicy/index.jsx";
import SafetySecurity from "./pages/Safety&Security/index.jsx";
import Guidelines from "./pages/Guidelines/index.jsx";

import Support from "./pages/Support/index.jsx";
import ScrollToTop from "./components/ScrollTop.jsx";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Turkish" element={<LandingpageTA />} />
        <Route path="/French" element={<LandingpageFR />} />
        <Route path="/Portuguese" element={<LandingpagePT />} />
        <Route path="/Spanish" element={<LandingpageSP />} />
        <Route path="/Swahili" element={<LandingpageSW />} />
        <Route path="/Thankyou" element={<Thankyou />} />
        <Route path="/TermsofUse" element={<Trems />} />
        <Route path="/PrivacyPolicyandCookies" element={<Privacy />} />
        <Route path="/SafetySecurity" element={<SafetySecurity />} />
        <Route path="/Guidelines" element={<Guidelines />} />
        <Route path="/Support" element={<Support />} />
      </Routes>
    </div>
  );
}
// <span>&#174;</span>

export default App;
