import React from "react";
import "./secondsectin.css";
import sec6img from "../../../../assets/Buissnesspartner.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div_bus">
        <div className="Newsection2_img_div_bus">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection2_text_div">
          <p>
            <span>TürkAfrica B2B Uygulaması</span>, Türkiye ve Afrika'daki
            işletmeleri ve girişimcileri birbirine bağlayan en büyük B2B ağ
            platformudur. Yatırımcılar, iş birlikleri, şirketler ve ticaret
            organizasyonlarından oluşan geniş bir veri tabanıyla, uygulama,
            ikili ticaret, yatırım ve inovasyonu teşvik eden bir işbirliği alanı
            yaratır. İleri teknolojiden yararlanarak, TürkAfrica B2B Uygulaması
            kullanıcıların yeni fırsatlar keşfetmesini, stratejik ortaklıklar
            kurmasını ve bu gelişen pazarların tüm potansiyelini açığa
            çıkarmasını sağlar.
          </p>
        </div>
        {/* Download */}
      </div>
    </div>
  );
};

export default index;
