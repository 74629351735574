import React from "react";
import "./secondsectin.css";
import sec6img from "../../../../assets/Buissnesspartner.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div_bus">
        <div className="Newsection2_img_div_bus">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection2_text_div">
          <p>
            O <span>Aplicativo B2B TurkAfrica</span> é a maior plataforma de
            networking B2B, conectando empresas e empreendedores da Turquia e da
            África. Com um extenso banco de dados de investidores, associações
            empresariais, empresas e organizações comerciais, o aplicativo cria
            um espaço colaborativo que impulsiona o comércio bilateral, o
            investimento e a inovação. Ao aproveitar a tecnologia avançada, o
            aplicativo TürkAfrica B2B permite aos usuários descobrir novas
            oportunidades, construir parcerias estratégicas e desbloquear todo o
            potencial desses mercados em crescimento.
          </p>
        </div>
        {/* Download */}
      </div>
    </div>
  );
};

export default index;
