import React from "react";
import "./secondsectin.css";
import sec6img from "../../../../assets/Buissnesspartner.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div_bus">
        <div className="Newsection2_img_div_bus">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection2_text_div">
          <p>
            <span>Programu ya TurkAfrica B2B</span> ni jukwaa kubwa zaidi la
            mtandao wa B2B, linalounganisha biashara na wajasiriamali kutoka
            Türkiye na Afrika. Ikiwa na hifadhidata kubwa ya wawekezaji, vyama
            vya biashara, kampuni, na mashirika ya biashara, programu hii
            inaunda nafasi ya ushirikiano ambayo inakuza biashara za pande
            mbili, uwekezaji, na uvumbuzi. Kwa kutumia teknolojia ya hali ya
            juu, programu ya TürkAfrica B2B inawawezesha watumiaji kugundua
            fursa mpya, kujenga ushirikiano wa kimkakati, na kufungua uwezo
            kamili wa masoko haya yanayokua.
          </p>
        </div>
        {/* Download */}
      </div>
    </div>
  );
};

export default index;
