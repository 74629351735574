import React from "react";
import "./Newsection.css";
import sec6img from "../../../../assets/Newsection.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";

const Index = () => {
  return (
    <div className="Newsection" id="Investors">
      <div className="submain_Newsection_div">
        <div className="Newsection_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection_text_div">
          <h1>
            Participation et Opportunités <br /> d'Investissement
          </h1>
          <p>
            Si vous êtes intéressé à explorer les opportunités de devenir un
            actionnaire valorisé ou souhaitez investir dans{" "}
            <b>TurkAfrica B2B App</b>, nous vous invitons chaleureusement à nous
            contacter. Pour plus de détails et pour entamer une conversation,
            veuillez vous rendre à la section 'Contact'. Nous attendons avec
            impatience l'opportunité de nous connecter avec vous.
          </p>
          <div className="Newsection_store_div">
            <p>Téléchargez l'application maintenant</p>
            <div
              className="store_img"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <div className="tooltip-container">
                <span className="tooltip">Téléchargez maintenant</span>

                <a
                  className="text store_img"
                  href="https://apps.apple.com/app/turkafrica-b2b-app/id6475347626"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={applelogo} alt="Apple Store" />
                </a>
              </div>
              <div className="tooltip-container">
                <span className="tooltip">Téléchargez maintenant</span>

                <a
                  className="text store_img"
                  href="https://play.google.com/store/apps/details?id=com.amin.turk_africa"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={playlogo} alt="Google Play Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
