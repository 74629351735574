import React from "react";
import "./secondsectin.css";
import sec6img from "../../../../assets/Buissnesspartner.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div_bus">
        <div className="Newsection2_img_div_bus">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection2_text_div">
          <p>
            La <span>Aplicación B2B TurkAfrica</span> es la mayor plataforma de
            networking B2B, que conecta empresas y emprendedores de Türkiye y
            África. Con una amplia base de datos de inversores, asociaciones
            empresariales, empresas y organizaciones comerciales, la aplicación
            crea un espacio colaborativo que impulsa el comercio bilateral, la
            inversión y la innovación. Al aprovechar la tecnología avanzada, la
            aplicación TürkAfrica B2B permite a los usuarios descubrir nuevas
            oportunidades, construir asociaciones estratégicas y desbloquear el
            potencial completo de estos mercados en crecimiento.
          </p>
        </div>
        {/* Download */}
      </div>
    </div>
  );
};

export default index;
