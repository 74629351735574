import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";

const Index = () => {
  const [lang, setlang] = useState();
  console.log("🚀 ~ Index ~ lang:", lang);
  const pathname = useLocation();
  const getname = pathname?.state?.pathname?.pathname;
  useEffect(() => {
    setlang(getname);
  }, [pathname]);
  const Navigation = useNavigate();

  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
              ? Navigation("/French")
              : lang == "/Portuguese"
              ? Navigation("/Portuguese")
              : lang == "/Swahili"
              ? Navigation("/Swahili")
              : lang == "/Spanish"
              ? Navigation("/Spanish")
              : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>
      <h3 className="head_text">
        {lang == "/Turkish"
          ? "TürkAfrica B2B App Kullanım Koşulları"
          : lang == "/French"
          ? "Conditions d'Utilisation de TürkAfrica B2B App"
          : lang == "/Portuguese"
          ? "Termos de Uso da TürkAfrica B2B App"
          : lang == "/Swahili"
          ? "Vigezo vya Matumizi ya TürkAfrica B2B App"
          : lang == "/Spanish"
          ? "Términos de Uso de TürkAfrica B2B App"
          : "TürkAfrica B2B App Terms of Use"}
      </h3>
      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>4 Ekim 2024'te Güncellendi</h6>
            </div>
            <div className="text_div">
              <p>
                Bu Kullanım Koşulları ("Koşullar"), TürkAfrica B2B
                Uygulaması'nın ("Uygulama") kullanımınızı düzenler ve United
                Entertainment & Media Limited ("biz", "bizim" veya "bizi")
                tarafından işletilmektedir. Uygulamaya erişerek veya kullanarak,
                bu Koşullara uymayı ve bağlı kalmayı kabul edersiniz. Bu
                Koşulların herhangi bir kısmına katılmıyorsanız, Uygulamayı
                kullanmamalısınız.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica B2B Uygulaması, Afrika ve Türkiye'deki işletmeleri ve
                girişimcileri birbirine bağlamak için tasarlanmış bir iş ağ ve
                ticaret platformudur. Uygulama, kullanıcılara ağ kurma
                fırsatları, ücretsiz mesajlaşma, sesli aramalar, video
                konferanslar (premium özellik), işletme eşleştirme hizmetleri ve
                işletme ve yatırımcı dizinlerine erişim sağlar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Uygunluk </h1>
              <p>
                Uygulama, en az 18 yaşında olan ve Birleşik Krallık yasalarına
                göre bağlayıcı sözleşmelere girme yeteneğine sahip kullanıcılar
                içindir. Uygulamayı kullanarak, bu uygunluk şartlarını
                karşıladığınızı onaylarsınız.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Kayıt ve Hesap</h1>
              <p>
                Uygulamanın belirli özelliklerine erişmek için kaydolmanız ve
                bir hesap oluşturmanız gerekebilir. Şunları kabul edersiniz:{" "}
                <br /> • Kayıt sırasında doğru ve güncel bilgi sağlamak. <br />{" "}
                • Hesap kimlik bilgilerinizi gizli tutmak. <br /> • Hesabınızda
                gerçekleşen tüm faaliyetlerden sorumlu olmak. <br /> •
                Hesabınızın yetkisiz kullanımını hemen bize bildirmek. Bu
                Koşulları ihlal ettiğinizi tespit edersek, hesabınızı herhangi
                bir zamanda feshetme veya askıya alma hakkımızı saklı tutarız.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Sağlanan Hizmetler</h1>
              <p>
                <span>• Ücretsiz B2B Sohbet Mesajlaşması: </span> Tüm kayıtlı
                kullanıcılar, diğer işletmelerle iletişim kurmak için ücretsiz
                mesajlaşma hizmetini kullanabilir. <br />
                <span>• Sesli Aramalar ve Video Konferanslar: </span> Bu
                özellikler, iş tartışmaları ve müzakereler için sorunsuz
                iletişimi sağlamak amacıyla premium kullanıcılara sunulmuştur.{" "}
                <br /> <span>• İşletme Eşleştirme: </span> Uygulama, kullanıcı
                tercihlerine ve sektöre göre ilgili iş bağlantıları,
                tedarikçiler veya yatırımcılarla kullanıcıları eşleştirmek için
                algoritmalar sunar. <br /> <span>• Veritabanı Erişimi: </span>{" "}
                Kullanıcılar, yeni pazarları ve fırsatları keşfetmek için
                işletme dizinlerine ve yatırımcı veritabanlarına erişim sağlar.
                <br />
                <span>• Ve Daha Fazlası.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Kullanıcı Sorumlulukları</h1>
              <p>
                Uygulamayı kullanırken, şunları kabul edersiniz: <br />{" "}
                <ul>
                  {" "}
                  <li>
                    Yalnızca yasal amaçlar için Uygulamayı kullanmak.
                  </li>{" "}
                  <li>
                    Yasa dışı, iftira niteliğinde veya zararlı içerikler
                    yayınlamaktan kaçınmak.
                  </li>{" "}
                  <li>
                    Diğer kullanıcılara saygı göstermek ve taciz, istismar veya
                    uygunsuz davranışlardan kaçınmak.
                  </li>{" "}
                  <li>
                    İhracat kontrol yasaları, ticaret yaptırımları ve gizlilik
                    düzenlemeleri dahil olmak üzere Uygulamayı kullanırken
                    yürürlükteki tüm yasa ve yönetmeliklere uymak.
                  </li>{" "}
                </ul>{" "}
                Uygulamada paylaştığınız içerik ve bilgilerden yalnızca sizin
                sorumlu olduğunuzu kabul edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Yasaklanan Faaliyetler</h1>
              <p>
                Şunları yapmamayı kabul edersiniz: <br />{" "}
                <ul>
                  {" "}
                  <li>
                    Dolandırıcı, yasa dışı veya aldatıcı faaliyetlerde bulunmak
                    için Uygulamayı kullanmak.
                  </li>{" "}
                  <li>
                    Uygulamaya veya kullanıcılarına zarar verebilecek virüs,
                    kötü amaçlı yazılım veya başka zararlı yazılımlar iletmek.
                  </li>{" "}
                  <li>
                    Uygulamayı hacklemeye, bozmayı veya tersine mühendislik
                    yapmaya çalışmak.
                  </li>{" "}
                  <li>
                    İstenmeyen ticari iletişimler (spam) göndermek için
                    Uygulamayı kötüye kullanmak.
                  </li>{" "}
                  <li>
                    Diğer kullanıcıları taklit etmek veya bir işletme veya
                    bireyle ilişkinizi yanlış beyan etmek.
                  </li>{" "}
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Premium Özellikler ve Ödeme</h1>
              <p>
                Sohbet mesajlaşması gibi temel hizmetler ücretsizken, sesli
                aramalar ve video konferanslar gibi bazı özellikler premium
                abonelik gerektirir. Premium özellikler ve fiyatlandırma ile
                ilgili detaylar Uygulamada mevcuttur. Premium hizmetler için
                ödemeler güvenli üçüncü taraf sağlayıcılar aracılığıyla işlenir
                ve bu tür işlemler için geçerli ödeme bilgileri sağlamayı kabul
                edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Fikri Mülkiyet</h1>
              <p>
                Uygulamadaki tüm fikri mülkiyet hakları, logolar, içerikler,
                tasarım ve yazılım dahil ancak bunlarla sınırlı olmamak üzere
                bize aittir veya bize lisanslanmıştır. İçeriği bizim yazılı
                iznimiz olmadan çoğaltamaz, dağıtamaz veya değiştiremezsiniz.{" "}
                <br />
                Uygulamaya gönderdiğiniz içeriklerin mülkiyetini elinizde
                tutarsınız, ancak Uygulama tarafından sunulan hizmetlerin bir
                parçası olarak içeriği kullanmamız, görüntülememiz ve dağıtmamız
                için bize dünya çapında, münhasır olmayan, telif ücreti
                ödenmeyen bir lisans verirsiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Gizlilik ve Veri Koruma</h1>
              <p>
                Gizliliğinize önem veriyoruz. Uygulamayı kullanarak,
                verilerinizin{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Gizlilik Politikamız
                </a>{" "}
                çerçevesinde toplanmasını ve kullanılmasını kabul etmiş
                olursunuz. Genel Veri Koruma Yönetmeliği'ne (GDPR) dahil olmak
                üzere geçerli veri koruma yasalarına uyarız.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Sorumluluk Sınırlaması</h1>
              <p>
                Yasaların izin verdiği ölçüde, Uygulamayı kullanmanızdan
                kaynaklanan dolaylı, arızi veya sonuçsal zararlardan sorumlu
                tutulamayız. Bu, iş fırsatlarının kaybı, veri, kâr veya diğer
                ekonomik kayıplar da dahil olmak üzere ancak bunlarla sınırlı
                değildir. Kullanıcılar tarafından paylaşılan içeriklerin
                doğruluğunu veya güvenilirliğini garanti etmiyoruz ve Uygulamayı
                kullanan herhangi bir işletme veya kişiyi onaylamıyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Fesih</h1>
              <p>
                Uygulamada yasa dışı veya uygunsuz faaliyetlerde bulunduğunuzu
                tespit edersek, bu Koşulları ihlal ettiğinizi tespit ettiğimizde
                hesabınızı dondurma veya feshetme hakkını saklı tutarız.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Üçüncü Taraf Bağlantılar</h1>
              <p>
                Uygulama, bizim sahip olmadığımız veya kontrol etmediğimiz
                üçüncü taraf web sitelerine veya hizmetlere bağlantılar
                içerebilir. Herhangi bir üçüncü taraf web sitesinin
                içeriklerinden, gizlilik politikalarından veya uygulamalarından
                sorumlu değiliz. Üçüncü taraf web sitelerini kullanmanızın kendi
                sorumluluğunuzda olduğunu kabul edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Geçerli Hukuk</h1>
              <p>
                Bu Koşullar, Birleşik Krallık yasalarına tabidir ve bu yasalara
                göre yorumlanır. Bu Koşullardan kaynaklanan veya bunlarla
                bağlantılı herhangi bir anlaşmazlık, Birleşik Krallık
                mahkemelerinin münhasır yargı yetkisine tabidir.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Koşulların Değiştirilmesi</h1>
              <p>
                Bu Koşulları herhangi bir zamanda değiştirme veya güncelleme
                hakkımız saklıdır. Kullanıcılara önemli değişiklikleri e-posta
                veya Uygulama aracılığıyla bildireceğiz. Bu tür değişikliklerden
                sonra Uygulamayı kullanmaya devam etmeniz, yeni Koşulları kabul
                ettiğiniz anlamına gelir.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Bize Ulaşın</h1>
              <p>
                Bu Koşullar hakkında herhangi bir sorunuz veya endişeniz varsa,
                lütfen bize şu iletişim formu aracılığıyla ulaşın:{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mise à jour le 4 octobre 2024</h6>
            </div>
            <div className="text_div">
              <p>
                Ces Conditions d'utilisation ("Conditions") régissent votre
                utilisation de l'application TürkAfrica B2B ("l'Application"),
                exploitée par United Entertainment & Media Limited ("nous",
                "notre" ou "nos"). En accédant ou en utilisant l'Application,
                vous acceptez de respecter ces Conditions. Si vous n'êtes pas
                d'accord avec une partie de ces Conditions, vous ne devez pas
                utiliser l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                L'application TürkAfrica B2B est une plateforme de mise en
                réseau et de commerce interentreprises conçue pour connecter des
                entreprises et des entrepreneurs d'Afrique et de Turquie.
                L'application offre aux utilisateurs des opportunités de
                réseautage, de messagerie gratuite, d'appels vocaux, de
                vidéoconférence (fonctionnalité premium), de services de mise en
                relation d'entreprises et des répertoires d'entreprises et
                d'investisseurs.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Admissibilité</h1>
              <p>
                L'Application est destinée aux utilisateurs âgés d'au moins 18
                ans et ayant la capacité juridique de conclure des contrats
                contraignants en vertu des lois du Royaume-Uni. En utilisant
                l'Application, vous confirmez que vous répondez à ces critères
                d'admissibilité.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Inscription et Compte</h1>
              <p>
                Pour accéder à certaines fonctionnalités de l'Application, vous
                pouvez être amené à vous inscrire et à créer un compte. Vous
                acceptez de : <br />
                • Fournir des informations exactes et à jour lors de
                l'inscription. <br />• Maintenir la confidentialité de vos
                identifiants de compte. <br /> • Être responsable de toutes les
                activités se produisant sous votre compte.
                <br /> • Nous informer immédiatement de toute utilisation non
                autorisée de votre compte. Nous nous réservons le droit de
                suspendre ou de résilier votre compte à tout moment si nous
                déterminons que vous avez violé ces Conditions.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Services Offerts</h1>
              <p>
                <span>• Messagerie B2B gratuite : </span> Tous les utilisateurs
                inscrits peuvent utiliser le service de messagerie gratuit pour
                communiquer avec d'autres entreprises. <br />
                <span>• Appels Vocaux et Vidéoconférences : </span> Disponibles
                pour les utilisateurs premium, ces fonctionnalités facilitent
                une communication fluide pour les discussions et négociations
                commerciales. <br />{" "}
                <span>• Mise en Relation d'Entreprises : </span> L'application
                propose des algorithmes pour mettre en relation les utilisateurs
                avec des contacts commerciaux, fournisseurs ou investisseurs
                pertinents, en fonction des préférences et du secteur d'activité
                de l'utilisateur. <br />
                <span>• Accès à la Base de Données : </span> Les utilisateurs
                ont accès à des répertoires d'entreprises et de bases de données
                d'investisseurs pour explorer de nouveaux marchés et
                opportunités.
                <br />
                <span>• Et Plus.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Responsabilités des Utilisateurs</h1>
              <p>
                En utilisant l'Application, vous acceptez de :{" "}
                <ul>
                  {" "}
                  <li>
                    Utiliser l'Application uniquement à des fins légales.
                  </li>{" "}
                  <li>
                    Vous abstenir de publier ou de partager des contenus
                    illégaux, diffamatoires ou nuisibles.
                  </li>
                  <li>
                    Respecter les autres utilisateurs et vous abstenir de toute
                    forme de harcèlement, d'abus ou de comportement inapproprié.
                  </li>{" "}
                  <li>
                    Respecter toutes les lois et réglementations applicables
                    lors de l'utilisation de l'Application, y compris les lois
                    sur le contrôle des exportations, les sanctions commerciales
                    et les règlements sur la protection de la vie privée.
                  </li>{" "}
                </ul>{" "}
                Vous reconnaissez que vous êtes seul responsable du contenu et
                des informations que vous partagez sur l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Activités Interdites</h1>
              <p>
                Vous acceptez de ne pas :{" "}
                <ul>
                  {" "}
                  <li>
                    Utiliser l'Application pour vous livrer à des activités
                    frauduleuses, illégales ou trompeuses.
                  </li>{" "}
                  <li>
                    Transmettre des virus, des logiciels malveillants ou
                    d'autres logiciels nuisibles qui pourraient endommager
                    l'Application ou ses utilisateurs.
                  </li>{" "}
                  <li>
                    Tenter de pirater, perturber ou désassembler l'Application.
                  </li>{" "}
                  <li>
                    Utiliser abusivement l'Application pour envoyer des
                    communications commerciales non sollicitées (spam).
                  </li>{" "}
                  <li>
                    Imiter d'autres utilisateurs ou usurper l'identité d'une
                    entreprise ou d'une personne.
                  </li>{" "}
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Fonctions Premium et Paiement</h1>
              <p>
                Alors que les services de base tels que la messagerie sont
                gratuits, certaines fonctionnalités (par exemple, les appels
                vocaux, la vidéoconférence) nécessitent un abonnement premium.
                Les détails des fonctionnalités premium et des tarifs sont
                disponibles sur l'Application. Les paiements pour les services
                premium sont traités par des prestataires tiers sécurisés, et
                vous acceptez de fournir des informations de paiement valides
                pour ces transactions.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Propriété Intellectuelle</h1>
              <p>
                Tous les droits de propriété intellectuelle relatifs à
                l'Application, y compris mais sans s'y limiter, les logos,
                contenus, conceptions et logiciels, nous appartiennent ou nous
                sont concédés sous licence. Vous ne pouvez pas reproduire,
                distribuer ou modifier tout contenu sans notre consentement
                écrit. <br />
                Vous conservez la propriété de tout contenu que vous publiez sur
                l'Application, mais vous nous accordez une licence mondiale,
                non-exclusive et libre de droits pour utiliser, afficher et
                distribuer le contenu dans le cadre des services fournis par
                l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Confidentialité et Protection des Données</h1>
              <p>
                Nous prenons votre vie privée au sérieux. En utilisant
                l'Application, vous acceptez la collecte et l'utilisation de vos
                données conformément à notre{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Politique de Confidentialité
                </a>
                . Nous respectons les lois applicables en matière de protection
                des données, y compris le Règlement Général sur la Protection
                des Données (RGPD).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Limitation de Responsabilité</h1>
              <p>
                Dans la mesure permise par la loi, nous ne sommes pas
                responsables des dommages indirects, accessoires ou consécutifs
                résultant de votre utilisation de l'Application. Cela inclut,
                mais sans s'y limiter, la perte d'opportunités commerciales, de
                données, de bénéfices ou d'autres pertes économiques. Nous ne
                garantissons pas l'exactitude ou la fiabilité du contenu publié
                par les utilisateurs, ni n'approuvons les entreprises ou
                individus utilisant l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Résiliation</h1>
              <p>
                Nous nous réservons le droit de suspendre ou de résilier votre
                compte à notre discrétion si nous déterminons que vous avez
                violé ces Conditions ou que vous vous êtes livré à des activités
                illégales ou inappropriées sur l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Liens de Tiers</h1>
              <p>
                L'Application peut contenir des liens vers des sites web ou
                services tiers qui ne nous appartiennent pas ou que nous ne
                contrôlons pas. Nous ne sommes pas responsables du contenu, des
                politiques de confidentialité ou des pratiques des sites tiers.
                Vous reconnaissez que l'utilisation des sites tiers est à vos
                propres risques.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Loi Applicable</h1>
              <p>
                Ces Conditions sont régies et interprétées conformément aux lois
                du Royaume-Uni. Tout litige découlant de ou lié à ces Conditions
                sera soumis à la compétence exclusive des tribunaux du
                Royaume-Uni.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Modifications des Conditions</h1>
              <p>
                Nous nous réservons le droit de modifier ou de mettre à jour ces
                Conditions à tout moment. Nous informerons les utilisateurs de
                toute modification importante par e-mail ou via l'Application.
                Votre utilisation continue de l'Application après de telles
                modifications constitue une acceptation des nouvelles
                Conditions.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Contactez-Nous</h1>
              <p>
                Si vous avez des questions ou des préoccupations concernant ces
                Conditions, veuillez nous contacter via notre formulaire de
                contact à{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 4 de outubro de 2024</h6>
            </div>
            <div className="text_div">
              <p>
                Estes Termos de Uso ("Termos") regem o uso do Aplicativo
                TürkAfrica B2B ("o Aplicativo"), operado por United
                Entertainment & Media Limited ("nós", "nosso" ou "nos"). Ao
                acessar ou utilizar o Aplicativo, você concorda em cumprir e
                estar vinculado a estes Termos. Se você não concorda com
                qualquer parte destes Termos, não deve usar o Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                O Aplicativo TürkAfrica B2B é uma plataforma de networking e
                comércio business-to-business projetada para conectar empresas e
                empreendedores da África e da Turquia. O Aplicativo oferece aos
                usuários acesso a oportunidades de networking, mensagens
                gratuitas, chamadas de voz, videoconferências (recurso premium),
                serviços de matchmaking de negócios e diretórios de empresas e
                investidores.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidade</h1>
              <p>
                O Aplicativo é destinado a usuários com pelo menos 18 anos de
                idade e que tenham capacidade legal para firmar contratos
                vinculativos sob as leis do Reino Unido. Ao usar o Aplicativo,
                você confirma que atende a esses requisitos de elegibilidade.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro e Conta</h1>
              <p>
                Para acessar certos recursos do Aplicativo, você pode ser
                solicitado a se registrar e criar uma conta. Você concorda em:{" "}
                <br /> • Fornecer informações precisas e atualizadas durante o
                registro. <br /> • Manter a confidencialidade das credenciais de
                sua conta. <br /> • Ser responsável por todas as atividades que
                ocorrerem em sua conta. <br /> • Nos notificar imediatamente
                sobre qualquer uso não autorizado de sua conta. Reservamo-nos o
                direito de encerrar ou suspender sua conta a qualquer momento,
                se determinarmos que você violou estes Termos.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Serviços Oferecidos</h1>
              <p>
                <span>• Mensagens B2B Gratuitas: </span> Todos os usuários
                registrados podem usar o serviço de mensagens gratuitas para se
                comunicar com outras empresas. <br />
                <span>• Chamadas de Voz e Videoconferências: </span> Disponível
                para usuários premium, esses recursos facilitam a comunicação
                para discussões e negociações comerciais. <br />{" "}
                <span>• Matchmaking de Negócios: </span> O Aplicativo oferece
                algoritmos para combinar os usuários com contatos comerciais
                relevantes, fornecedores ou investidores, com base nas
                preferências do usuário e no setor. <br />{" "}
                <span>Acesso ao Banco de Dados: </span> Os usuários têm acesso a
                diretórios de empresas e bancos de dados de investidores para
                explorar novos mercados e oportunidades.
                <br />
                <span>• E Mais.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Responsabilidades do Usuário</h1>
              <p>
                Ao usar o Aplicativo, você concorda em:{" "}
                <ul>
                  {" "}
                  <li>Usar o Aplicativo apenas para fins legais.</li>{" "}
                  <li>
                    Abster-se de postar ou compartilhar conteúdos ilegais,
                    difamatórios ou prejudiciais.
                  </li>{" "}
                  <li>
                    Respeitar os outros usuários e abster-se de se envolver em
                    assédio, abuso ou comportamento inadequado.
                  </li>{" "}
                  <li>
                    Cumprir todas as leis e regulamentos aplicáveis ao usar o
                    Aplicativo, incluindo leis de controle de exportação,
                    sanções comerciais e regulamentos de privacidade.
                  </li>{" "}
                </ul>{" "}
                Você reconhece que é o único responsável pelo conteúdo e pelas
                informações que compartilha no Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Atividades Proibidas</h1>
              <p>
                Você concorda em não:{" "}
                <ul>
                  {" "}
                  <li>
                    Usar o Aplicativo para se envolver em atividades
                    fraudulentas, ilegais ou enganosas.
                  </li>{" "}
                  <li>
                    Transmitir vírus, malware ou outros softwares prejudiciais
                    que possam danificar o Aplicativo ou seus usuários.
                  </li>{" "}
                  <li>
                    Tentar hackear, interromper ou fazer engenharia reversa no
                    Aplicativo.
                  </li>{" "}
                  <li>
                    Usar o Aplicativo para enviar comunicações comerciais não
                    solicitadas (spam).
                  </li>{" "}
                  <li>
                    Se passar por outros usuários ou deturpar sua afiliação a
                    uma empresa ou indivíduo.
                  </li>{" "}
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Recursos Premium e Pagamento</h1>
              <p>
                Embora serviços básicos como mensagens de bate-papo sejam
                gratuitos, certos recursos (por exemplo, chamadas de voz,
                videoconferências) exigem uma assinatura premium. Detalhes sobre
                os recursos premium e preços estão disponíveis no Aplicativo.
                Pagamentos para serviços premium são processados por provedores
                terceiros seguros, e você concorda em fornecer detalhes de
                pagamento válidos para tais transações.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Propriedade Intelectual</h1>
              <p>
                Todos os direitos de propriedade intelectual do Aplicativo,
                incluindo logotipos, conteúdo, design e software, são de nossa
                propriedade ou são licenciados para nós. Você não pode
                reproduzir, distribuir ou modificar qualquer conteúdo sem o
                nosso consentimento por escrito. <br /> Você mantém a
                propriedade de qualquer conteúdo que postar no Aplicativo, mas
                nos concede uma licença mundial, não exclusiva e livre de
                royalties para usar, exibir e distribuir o conteúdo como parte
                dos serviços fornecidos pelo Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Privacidade e Proteção de Dados</h1>
              <p>
                Levamos sua privacidade a sério. Ao usar o Aplicativo, você
                concorda com a coleta e o uso de seus dados, conforme descrito
                em nossa{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Política de Privacidade
                </a>{" "}
                . Cumprimos as leis de proteção de dados aplicáveis, incluindo o
                Regulamento Geral de Proteção de Dados (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Limitação de Responsabilidade</h1>
              <p>
                Na medida permitida pela lei, não somos responsáveis por
                quaisquer danos indiretos, incidentais ou consequenciais
                decorrentes do uso do Aplicativo. Isso inclui, mas não se limita
                a, perda de oportunidades comerciais, dados, lucros ou outras
                perdas econômicas. Não garantimos a precisão ou confiabilidade
                do conteúdo postado pelos usuários, nem endossamos quaisquer
                empresas ou indivíduos que utilizam o Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Rescisão</h1>
              <p>
                Reservamo-nos o direito de suspender ou encerrar sua conta a
                nosso exclusivo critério, se determinarmos que você violou estes
                Termos ou se envolveu em atividades ilegais ou inadequadas no
                Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Links de Terceiros</h1>
              <p>
                O Aplicativo pode conter links para sites ou serviços de
                terceiros que não são de nossa propriedade ou controle. Não
                somos responsáveis pelo conteúdo, políticas de privacidade ou
                práticas de quaisquer sites de terceiros. Você reconhece que o
                uso de sites de terceiros é por sua conta e risco.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Lei Aplicável</h1>
              <p>
                Estes Termos são regidos e interpretados de acordo com as leis
                do Reino Unido. Qualquer disputa decorrente destes Termos será
                sujeita à jurisdição exclusiva dos tribunais do Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Alterações nos Termos</h1>
              <p>
                Reservamo-nos o direito de modificar ou atualizar estes Termos a
                qualquer momento. Notificaremos os usuários sobre quaisquer
                mudanças importantes por e-mail ou por meio do Aplicativo. Seu
                uso continuado do Aplicativo após tais modificações constitui
                aceitação dos novos Termos.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Contate-Nos</h1>
              <p>
                Se você tiver dúvidas ou preocupações sobre estes Termos, entre
                em contato conosco através de nosso formulário de contato em{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 4 Oktoba 2024</h6>
            </div>
            <div className="text_div">
              <p>
                Masharti haya ya Matumizi ("Masharti") yanadhibiti matumizi yako
                ya Programu ya TürkAfrica B2B ("Programu"), inayoendeshwa na
                United Entertainment & Media Limited ("sisi", "yetu" au
                "kwetu"). Kwa kufikia au kutumia Programu, unakubali kufuata na
                kufungwa na Masharti haya. Ikiwa haukubaliani na sehemu yoyote
                ya Masharti haya, hupaswi kutumia Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Programu ya TürkAfrica B2B ni jukwaa la mitandao na biashara
                kati ya biashara na biashara, iliyoundwa kuunganisha
                wafanyabiashara na wajasiriamali kutoka Afrika na Uturuki.
                Programu hii inawapa watumiaji fursa za mitandao, ujumbe wa
                bure, simu za sauti, mikutano ya video (kipengele cha malipo),
                huduma za ulinganishaji wa biashara, na orodha za biashara na
                wawekezaji.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Ustahiki</h1>
              <p>
                Programu hii inakusudiwa kwa watumiaji walio na umri wa miaka 18
                au zaidi na wenye uwezo wa kisheria wa kuingia mikataba ya
                kisheria chini ya sheria za Uingereza. Kwa kutumia Programu,
                unathibitisha kuwa unakidhi mahitaji haya ya ustahiki.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usajili na Akaunti</h1>
              <p>
                Ili kufikia vipengele fulani vya Programu, unaweza kuhitajika
                kujisajili na kuunda akaunti. Unakubali: <br /> • Kutoa taarifa
                sahihi na za sasa wakati wa usajili. <br /> • Kudumisha usiri wa
                vitambulisho vyako vya akaunti. <br /> • Kuwajibika kwa shughuli
                zote zinazofanywa chini ya akaunti yako. <br /> • Kutujuza mara
                moja ikiwa kuna matumizi yasiyoidhinishwa ya akaunti yako.
                Tunahifadhi haki ya kumaliza au kusimamisha akaunti yako wakati
                wowote ikiwa tutagundua kuwa umekiuka Masharti haya.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Huduma Zinazotolewa</h1>
              <p>
                <span>• Ujumbe wa Bure wa B2B: </span> Watumiaji wote
                waliosajiliwa wanaweza kutumia huduma ya ujumbe wa bure
                kuwasiliana na biashara nyingine. <br />
                <span>• Simu za Sauti na Mikutano ya Video: </span> Zinapatikana
                kwa watumiaji wa premium, huduma hizi zinasaidia mawasiliano ya
                biashara kwa majadiliano na mazungumzo. <br />{" "}
                <span>• Ulinganishaji wa Biashara: </span> Programu inatoa
                algoriti za kuwalinganisha watumiaji na mawasiliano muhimu ya
                biashara, wauzaji au wawekezaji kulingana na mapendeleo ya
                mtumiaji na sekta. <br /> <span>Ufikiaji wa Hifadhidata: </span>
                Watumiaji wanapata orodha za biashara na hifadhidata za
                wawekezaji ili kuchunguza masoko na fursa mpya.
                <br />
                <span>• Na Zaidi.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Majukumu ya Mtumiaji</h1>
              <p>
                Unapotumia Programu, unakubali:{" "}
                <ul>
                  {" "}
                  <li>Kutumia Programu kwa madhumuni halali tu.</li>{" "}
                  <li>
                    Kujizuia kuchapisha au kushiriki maudhui haramu,
                    yanayochafua jina au yenye madhara.
                  </li>{" "}
                  <li>
                    Kuheshimu watumiaji wengine na kujizuia kushiriki katika
                    unyanyasaji, matusi, au tabia isiyofaa.
                  </li>{" "}
                  <li>
                    Kuheshimu sheria zote zinazotumika na kanuni wakati wa
                    kutumia Programu, ikiwa ni pamoja na sheria za udhibiti wa
                    usafirishaji nje, vikwazo vya biashara, na kanuni za
                    faragha.
                  </li>{" "}
                </ul>{" "}
                Unakubali kuwa wewe pekee ndiye unawajibika kwa maudhui na
                taarifa unayoshiriki kwenye Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Shughuli Zinazokatazwa</h1>
              <p>
                Unakubali kutofanya:{" "}
                <ul>
                  {" "}
                  <li>
                    Kutumia Programu kushiriki katika shughuli za udanganyifu,
                    haramu au za udanganyifu.
                  </li>{" "}
                  <li>
                    Kutuma virusi, programu mbaya au programu nyingine zenye
                    madhara ambazo zinaweza kudhuru Programu au watumiaji wake.
                  </li>{" "}
                  <li>
                    Kujaribu kudukua, kuvuruga au kutengeneza upya Programu.
                  </li>{" "}
                  <li>
                    Kutumia vibaya Programu kutuma mawasiliano ya kibiashara
                    yasiyotakikana (spam).
                  </li>{" "}
                  <li>
                    Kujifanya kuwa watumiaji wengine au kupotosha uhusiano wako
                    na biashara au mtu binafsi.
                  </li>{" "}
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Vipengele vya Premium na Malipo</h1>
              <p>
                Wakati huduma za msingi kama vile ujumbe wa mazungumzo ni za
                bure, baadhi ya vipengele (kama vile simu za sauti, mikutano ya
                video) zinahitaji usajili wa premium. Maelezo ya vipengele vya
                premium na bei yanapatikana kwenye Programu. Malipo kwa huduma
                za premium yanashughulikiwa kupitia watoa huduma wa tatu salama,
                na unakubali kutoa maelezo halali ya malipo kwa ajili ya miamala
                hiyo.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Mali ya Kihisia</h1>
              <p>
                Haki zote za mali ya kiakili katika Programu, ikijumuisha lakini
                si kikomo kwa alama, maudhui, muundo, na programu, ni mali yetu
                au tumepatiwa leseni. Huwezi kuzalisha, kusambaza, au
                kurekebisha maudhui yoyote bila idhini yetu ya maandishi. <br />{" "}
                Unabaki kuwa mmiliki wa maudhui yoyote unayopakia kwenye
                Programu lakini unatupatia leseni ya kimataifa, isiyo ya
                kipekee, isiyolipiwa na ya kifalme ya kutumia, kuonyesha na
                kusambaza maudhui kama sehemu ya huduma zinazotolewa na
                Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Faragha na Ulinzi wa Takwimu</h1>
              <p>
                Tunachukua faragha yako kwa uzito. Kwa kutumia Programu,
                unakubali ukusanyaji na matumizi ya data yako kama
                ilivyoainishwa katika{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Sera yetu ya Faragha
                </a>
                . Tunafuata sheria zinazotumika za ulinzi wa takwimu,
                ikijumuisha Kanuni ya Jumla ya Ulinzi wa Takwimu (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Kizuizi cha Wajibu</h1>
              <p>
                Kwa kadri inavyoruhusiwa na sheria, hatuwajibiki kwa hasara
                yoyote isiyo ya moja kwa moja, isiyo ya kawaida, au inayofuata
                kutokana na matumizi yako ya Programu. Hii ni pamoja na, lakini
                si kikomo kwa, hasara ya fursa za biashara, data, faida au
                hasara nyingine za kiuchumi. Hatuhakikishi usahihi au uaminifu
                wa maudhui yaliyowekwa na watumiaji, wala hatuungi mkono
                biashara au watu binafsi wanaotumia Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Kukomesha</h1>
              <p>
                Tunahifadhi haki ya kusimamisha au kusitisha akaunti yako kwa
                hiari yetu ikiwa tutagundua kuwa umekiuka Masharti haya au
                umejihusisha na shughuli zisizo halali au zisizofaa kwenye
                Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Viungo vya Watu wa Tatu</h1>
              <p>
                Programu inaweza kuwa na viungo vya tovuti au huduma za watu wa
                tatu ambazo hatumiliki au kudhibiti. Hatutawajibika kwa maudhui,
                sera za faragha au mazoea ya tovuti za watu wa tatu. Unakubali
                kuwa matumizi yako ya tovuti za watu wa tatu ni kwa hatari yako
                mwenyewe.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Sheria Inayotumika</h1>
              <p>
                Masharti haya yanadhibitiwa na kufasiriwa kwa mujibu wa sheria
                za Uingereza. Migogoro yoyote inayotokana na au kuhusiana na
                Masharti haya itakuwa chini ya mamlaka ya kipekee ya mahakama za
                Uingereza.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Mabadiliko ya Masharti</h1>
              <p>
                Tunahifadhi haki ya kurekebisha au kusasisha Masharti haya
                wakati wowote. Tutawajulisha watumiaji kuhusu mabadiliko yoyote
                muhimu kupitia barua pepe au kupitia Programu. Matumizi yako ya
                kuendelea ya Programu baada ya mabadiliko haya yatakuwa ni
                kukubali Masharti mapya.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Wasiliana Nasi</h1>
              <p>
                Ikiwa una maswali au wasiwasi kuhusu Masharti haya, tafadhali
                wasiliana nasi kupitia fomu yetu ya mawasiliano katika{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 4 de octubre de 2024</h6>
            </div>
            <div className="text_div">
              <p>
                Estos Términos de Uso ("Términos") rigen su uso de la aplicación
                TürkAfrica B2B ("la Aplicación"), operada por United
                Entertainment & Media Limited ("nosotros" o "nuestro"). Al
                acceder o utilizar la Aplicación, acepta cumplir con estos
                Términos. Si no está de acuerdo con alguna parte de estos
                Términos, no debe utilizar la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                La Aplicación TürkAfrica B2B es una plataforma de comercio y
                redes de negocios diseñada para conectar a empresas y
                emprendedores de África y Turquía. La Aplicación ofrece a los
                usuarios acceso a oportunidades de redes, mensajería gratuita,
                llamadas de voz, videoconferencias (característica premium),
                servicios de emparejamiento de negocios y directorios de
                empresas e inversores.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidad</h1>
              <p>
                La Aplicación está destinada a usuarios que tengan al menos 18
                años y la capacidad legal para celebrar contratos vinculantes
                bajo las leyes del Reino Unido. Al usar la Aplicación, confirma
                que cumple con estos requisitos de elegibilidad.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro y Cuenta</h1>
              <p>
                Para acceder a ciertas funciones de la Aplicación, es posible
                que deba registrarse y crear una cuenta. Usted acepta: <br /> •
                Proporcionar información precisa y actualizada durante el
                registro. <br /> • Mantener la confidencialidad de las
                credenciales de su cuenta. <br /> • Ser responsable de todas las
                actividades que ocurran bajo su cuenta. <br /> • Notificarnos de
                inmediato cualquier uso no autorizado de su cuenta. Nos
                reservamos el derecho de suspender o cancelar su cuenta en
                cualquier momento si determinamos que ha violado estos Términos.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Servicios Proporcionados</h1>
              <p>
                <span>• Mensajería B2B Gratuita: </span> Todos los usuarios
                registrados pueden utilizar el servicio de mensajería gratuita
                para comunicarse con otras empresas. <br />
                <span>• Llamadas de Voz y Videoconferencias: </span> Disponible
                para usuarios premium, estas funciones facilitan la comunicación
                fluida para discusiones y negociaciones comerciales. <br />{" "}
                <span>• Emparejamiento de Negocios: </span> La Aplicación ofrece
                algoritmos que emparejan a los usuarios con contactos
                comerciales relevantes, proveedores o inversores según las
                preferencias del usuario y el sector. <br />{" "}
                <span>Acceso a la Base de Datos: </span> Los usuarios tienen
                acceso a directorios de empresas y bases de datos de inversores
                para explorar nuevos mercados y oportunidades.
                <br />
                <span>• Y Más.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Responsabilidades del Usuario</h1>
              <p>
                Al usar la Aplicación, acepta:{" "}
                <ul>
                  {" "}
                  <li>Usar la Aplicación solo con fines legales.</li>{" "}
                  <li>
                    Abstenerse de publicar o compartir contenido ilegal,
                    difamatorio o perjudicial.
                  </li>{" "}
                  <li>
                    Respetar a otros usuarios y abstenerse de participar en
                    acoso, abuso o comportamiento inapropiado.
                  </li>{" "}
                  <li>
                    Cumplir con todas las leyes y regulaciones aplicables al
                    usar la Aplicación, incluidas las leyes de control de
                    exportaciones, sanciones comerciales y regulaciones de
                    privacidad.
                  </li>{" "}
                </ul>{" "}
                Usted reconoce que es el único responsable del contenido y la
                información que comparte en la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Actividades Prohibidas</h1>
              <p>
                Usted acepta no:{" "}
                <ul>
                  {" "}
                  <li>
                    Utilizar la Aplicación para participar en actividades
                    fraudulentas, ilegales o engañosas.
                  </li>{" "}
                  <li>
                    Transmitir virus, malware u otro software dañino que pueda
                    dañar la Aplicación o a sus usuarios.
                  </li>{" "}
                  <li>
                    Intentar hackear, interrumpir o desensamblar la Aplicación.
                  </li>{" "}
                  <li>
                    Usar la Aplicación para enviar comunicaciones comerciales no
                    solicitadas (spam).
                  </li>{" "}
                  <li>
                    Hacerse pasar por otros usuarios o tergiversar su afiliación
                    con una empresa o individuo.
                  </li>{" "}
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Características Premium y Pago</h1>
              <p>
                Si bien los servicios básicos como la mensajería son gratuitos,
                ciertas características (por ejemplo, llamadas de voz,
                videoconferencias) requieren una suscripción premium. Los
                detalles sobre las características premium y los precios están
                disponibles en la Aplicación. Los pagos para los servicios
                premium se procesan a través de proveedores terceros seguros, y
                usted acepta proporcionar detalles de pago válidos para dichas
                transacciones.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Propiedad Intelectual</h1>
              <p>
                Todos los derechos de propiedad intelectual de la Aplicación,
                incluidos, entre otros, logotipos, contenido, diseño y software,
                son de nuestra propiedad o están licenciados a nosotros. No
                puede reproducir, distribuir o modificar ningún contenido sin
                nuestro consentimiento por escrito. <br /> Usted conserva la
                propiedad de cualquier contenido que publique en la Aplicación,
                pero nos otorga una licencia mundial, no exclusiva y libre de
                regalías para usar, mostrar y distribuir el contenido como parte
                de los servicios proporcionados por la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Privacidad y Protección de Datos</h1>
              <p>
                Nos tomamos en serio su privacidad. Al utilizar la Aplicación,
                acepta la recopilación y el uso de sus datos como se describe en
                nuestra{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Política de Privacidad
                </a>
                . Cumplimos con las leyes de protección de datos aplicables,
                incluido el Reglamento General de Protección de Datos (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Limitación de Responsabilidad</h1>
              <p>
                En la medida permitida por la ley, no somos responsables por
                daños indirectos, incidentales o consecuentes que surjan de su
                uso de la Aplicación. Esto incluye, entre otros, la pérdida de
                oportunidades comerciales, datos, beneficios u otras pérdidas
                económicas. No garantizamos la exactitud o confiabilidad del
                contenido publicado por los usuarios, ni respaldamos a ninguna
                empresa o individuo que utilice la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Terminación</h1>
              <p>
                Nos reservamos el derecho de suspender o cancelar su cuenta a
                nuestra discreción si determinamos que ha violado estos Términos
                o ha participado en actividades ilegales o inapropiadas en la
                Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Enlaces a Terceros</h1>
              <p>
                La Aplicación puede contener enlaces a sitios web o servicios de
                terceros que no son de nuestra propiedad ni están bajo nuestro
                control. No somos responsables del contenido, las políticas de
                privacidad ni las prácticas de los sitios web de terceros. Usted
                reconoce que el uso de sitios web de terceros es bajo su propio
                riesgo.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Ley Aplicable</h1>
              <p>
                Estos Términos se rigen e interpretan de acuerdo con las leyes
                del Reino Unido. Cualquier disputa que surja o esté relacionada
                con estos Términos estará sujeta a la jurisdicción exclusiva de
                los tribunales del Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Cambios en los Términos</h1>
              <p>
                Nos reservamos el derecho de modificar o actualizar estos
                Términos en cualquier momento. Notificaremos a los usuarios
                sobre cualquier cambio importante por correo electrónico o a
                través de la Aplicación. Su uso continuado de la Aplicación
                después de dichos cambios constituye su aceptación de los nuevos
                Términos.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Contáctenos</h1>
              <p>
                Si tiene alguna pregunta o inquietud acerca de estos Términos,
                comuníquese con nosotros a través de nuestro formulario de
                contacto en{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on October 4th, 2024</h6>
            </div>
            <div className="text_div">
              <p>
                These Terms of Use ("Terms") govern your use of the TürkAfrica
                B2B App ("the App"), operated by United Entertainment & Media
                Limited ("we", "our," or "us"). By accessing or using the App,
                you agree to comply with and be bound by these Terms. If you do
                not agree with any part of these Terms, you must not use the
                App.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                TürkAfrica B2B App is a business-to-business networking and
                trade platform designed to connect businesses and entrepreneurs
                from Africa and Türkiye. The App provides users with access to
                networking opportunities, free messaging, voice calls, video
                conferencing (premium feature), business matchmaking services,
                and directories of businesses and investors.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Eligibility </h1>
              <p>
                The App is intended for users who are at least 18 years old and
                have the legal capacity to enter into binding contracts under
                the laws of the United Kingdom. By using the App, you confirm
                that you meet these eligibility requirements.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registration & Account</h1>
              <p>
                To access certain features of the App, you may be required to
                register and create an account. You agree to: <br /> • Provide
                accurate and up-to-date information during registration. <br />
                • Maintain the confidentiality of your account credentials.
                <br /> • Be responsible for all activities that occur under your
                account.
                <br /> • Notify us immediately of any unauthorized use of your
                account. We reserve the right to terminate or suspend your
                account at any time if we determine that you have violated these
                Terms.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Services Provided</h1>
              <p>
                <span>• Free B2B Chat Messaging: </span> All registered users
                can use the free messaging service to communicate with other
                businesses. <br />
                <span>• Voice Calls & Video Conferences: </span> Available to
                premium users, these features facilitate seamless communication
                for business discussions and negotiations.
                <br /> <span>• Business Matchmaking: </span> The App offers
                algorithms to match users with relevant business contacts,
                suppliers, or investors based on user preferences and industry.{" "}
                <br />
                <span>• Database Access: </span>
                Users have access to business directories and investor databases
                to explore new markets and opportunities.
                <br />
                <span>• And More.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. User Responsibilities</h1>
              <p>
                When using the App, you agree to:
                <ui>
                  <li>Use the App for lawful purposes only.</li>
                  <li>
                    Refrain from posting or sharing illegal, defamatory, or
                    harmful content.
                  </li>
                  <li>
                    Respect other users and refrain from engaging in harassment,
                    abuse, or inappropriate behavior.
                  </li>
                  <li>
                    Comply with all applicable laws and regulations when using
                    the App, including export control laws, trade sanctions, and
                    privacy regulations.
                  </li>
                </ui>
                You acknowledge that you are solely responsible for the content
                and information you share on the App.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Prohibited Activities</h1>
              <p>
                You agree not to:
                <ul>
                  <li>
                    Use the App to engage in fraudulent, illegal, or deceptive
                    activities.
                  </li>
                  <li>
                    Transmit viruses, malware, or other harmful software that
                    may damage the App or its users.
                  </li>
                  <li>Attempt to hack, disrupt, or reverse-engineer the App</li>
                  <li>
                    Misuse the App to send unsolicited commercial communications
                    (spam).
                  </li>
                  <li>
                    Impersonate other users or misrepresent your affiliation
                    with a business or individual.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Premium Features & Payment</h1>
              <p>
                While basic services such as chat messaging are free, certain
                features (e.g., voice calls, video conferencing) require a
                premium subscription. Details of premium features and pricing
                are available on the App. Payments for premium services are
                processed through secure third-party providers, and you agree to
                provide valid payment details for such transactions.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Intellectual Property</h1>
              <p>
                All intellectual property rights in the App, including but not
                limited to logos, content, design, and software, are owned by or
                licensed to us. You may not reproduce, distribute, or modify any
                content without our written consent. <br />
                You retain ownership of any content you post on the App but
                grant us a worldwide, nonexclusive, royalty-free license to use,
                display, and distribute the content as part of the services
                provided by the App
              </p>
            </div>
            <div className="text_div">
              <h1>9. Privacy & Data Protection</h1>
              <p>
                We take your privacy seriously. By using the App, you agree to
                the collection and use of your data as outlined in our{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Privacy Policy
                </a>{" "}
                . We comply with applicable data protection laws, including the
                General Data Protection Regulation (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Limitation of Liability</h1>
              <p>
                To the extent permitted by law, we are not liable for any
                indirect, incidental, or consequential damages arising from your
                use of the App. This includes, but is not limited to, loss of
                business opportunities, data, profits, or other economic loss.
                We do not guarantee the accuracy or reliability of content
                posted by users, nor do we endorse any businesses or individuals
                using the App
              </p>
            </div>
            <div className="text_div">
              <h1>11. Termination</h1>
              <p>
                We reserve the right to suspend or terminate your account at our
                sole discretion if we determine that you have violated these
                Terms or engaged in any illegal or inappropriate activities on
                the App.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Third-Party Links</h1>
              <p>
                The App may contain links to third-party websites or services
                that are not owned or controlled by us. We are not responsible
                for the content, privacy policies, or practices of any
                third-party websites. You acknowledge that your use of
                third-party websites is at your own risk.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Governing Law</h1>
              <p>
                These Terms are governed by and construed in accordance with the
                laws of the United Kingdom. Any disputes arising from or in
                connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts of the United Kingdom.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Changes to the Terms</h1>
              <p>
                We reserve the right to modify or update these Terms at any
                time. We will notify users of any material changes via email or
                through the App. Your continued use of the App after such
                modifications constitutes acceptance of the new Terms.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Contact Us</h1>
              <p>
                If you have any questions or concerns about these Terms, please
                contact us at via our contact form at{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
