import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import d1Img from "../../../../assets/download/02.png";
import d2Img from "../../../../assets/download/03.png";
import d3Img from "../../../../assets/download/04.png";
import d4Img from "../../../../assets/download/05.png";
import QRcode from "../../../../assets/download/Qrcode.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Download">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>Pakua App ya “TurkAfrica B2B”</h1>
          <p>Ambapo akili za biashara bora za Afrika na Uturuki hukutana.</p>
        </div>
        {/* <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div> */}
        <div className="download_img_main_div">
          <div className="div_of_image_box">
            <p>
              Unda wasifu wa kitaalamu wa biashara na kuvutia washirika wa
              biashara wanaoweza kuwa.
            </p>
            <div className="img_of_download">
              <img src={d1Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Pata habari za hivi punde kuhusu mahusiano ya kibiashara ya Afrika
              na Uturuki! Baki na taarifa.
            </p>
            <div className="img_of_download">
              <img src={d2Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Jenga mtandao na wasiliana kupitia sauti, video, au kwa mazungumzo
              tu!
            </p>
            <div className="img_of_download">
              <img src={d3Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Kufanya mkutano wa video na washirika wako na wenzi wa biashara.
            </p>
            <div className="img_of_download">
              <img src={d4Img} alt="" />
            </div>
          </div>
        </div>
        <div className="store_div_download_section">
          <p>"Pata App! Scan QR Code ili kupakua sasa na uipate papo hapo!”</p>
          <div className="Qr_code_div">
            <img src={QRcode} alt="" />
          </div>
          <p>au</p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className="tooltip-container">
              <span className="tooltip">Pakua sasa</span>

              <a
                className="text store_img"
                href="https://apps.apple.com/app/turkafrica-b2b-app/id6475347626"
                target="_blank"
                rel="noopener noreferrer">
                <img src={applelogo} alt="Apple Store" />
              </a>
            </div>
            <div className="tooltip-container">
              <span className="tooltip">Pakua sasa</span>

              <a
                className="text store_img"
                href="https://play.google.com/store/apps/details?id=com.amin.turk_africa"
                target="_blank"
                rel="noopener noreferrer">
                <img src={playlogo} alt="Google Play Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
