import React from "react";
import "./secondsectin.css";
import sec6img from "../../../../assets/Buissnesspartner.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div_bus">
        <div className="Newsection2_img_div_bus">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection2_text_div">
          <p>
            The <span>TurkAfrica B2B App </span>is the largest B2B networking
            platform, connecting businesses and entrepreneurs from Türkiye and
            Africa. With an extensive database of investors, business
            associations, companies, and trade organizations, the app creates a
            collaborative space that drives bilateral trade, investment, and
            innovation. By leveraging advanced technology, the TürkAfrica B2B
            App enables users to discover new opportunities, build strategic
            partnerships, and unlock the full potential of these thriving
            markets.
          </p>
        </div>
        {/* Download */}
      </div>
    </div>
  );
};

export default index;
